import React, { useEffect, useState, useContext } from 'react'
import EventContext from '../MODELS/EVENT/EventContext';
import { jumpEvent3 } from '../MODELS/EVENT/EventModel';
import { useNavigate } from "react-router-dom";



const HomeView: React.FunctionComponent = (props) => {

    
    const [ width, setWidth ] = React.useState(window.innerWidth);

    
    const { events, event3, updateEvent3 } = useContext(EventContext);
    const [ currentEvent3, setCurrentEvent3 ] = useState([{ id: 0, date: '', name: '', picture: '', pdf: ''}]);


    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
        setCurrentEvent3(event3)
    }, [event3])

    const handleCursor = (cursor: number): void => { setCurrentEvent3(jumpEvent3(events, currentEvent3, cursor)) }


    return(
        <div className = 'wrapper width-100 height-100 align-center overflow-xnone overflow-ynone'>
        {width > 1000 || window.screen.orientation.type === 'portrait-primary' ? 
        <div className = 'cont column align-center overflow-xnone overflow-ynone' id = {width < 480 ? 'backMobile' : 'back' }>
            {/* --- ÉVES PROGRAM --- */}
            <button className = {width < 480 ? 'absolute margin-left-60 margin-top-30 button width-25 font-200' : 'absolute margin-top-5 button width-10' }  onClick = {() => navigate('/evesprogram')}>Éves prog</button>
            {/* --- MOBILE ARROWS --- */}
            {width < 480 ? 
                <div className = 'wrapper width-90 height-10 row justify-space align-center margin-top-3'>
                    <div className = 'arrowleft' id = 'arrowleftMobile' onClick = {() => handleCursor(-1)}></div>
                    <div className = 'arrowright' id = 'arrowrightMobile' onClick = {() => handleCursor(1)}></div>
                </div> 
                : ''
                }
            {/* --- OVERFLOW WRAPPER --- */}
            <div className = {width < 480 ? 'wrapper column width-100 height-100 justify-top align-center overflow-y margin-top-20' 
            : 'wrapper row width-100 height-100 justify-center align-bottom' } >
                 {/* --- ARROW LEFT --- */}
                <div className = {width < 480 ? 'arrowNone' : 'arrowleft'} id = {width < 480 ? '' : 'arrowleft'} onClick = {() => handleCursor(-1)}></div>
                {currentEvent3[0] !== undefined ?
                <>
                    {/* --- FIRST CARD --- */}
                    <div className = {width < 480 ? 'wrapper width-90 height-70 column justify-start align-center padding-top-1 margin-bottom-1 margin-right-1' 
                    : 'wrapper width-25 height-50 column justify-start align-center padding-top-1 margin-bottom-1 margin-right-1'} id = 'card'>
                        {/* --- HEAD WRAPPER --- */}
                        <div className = 'wrapper row width-90 height-10 justify-center margin-bottom-2' > 
                            <div className = {width < 480 ? 'wrapper width-60 height-100 column font-500 weight-500' 
                            : 'wrapper width-60 height-100 column font-300 weight-500' }> {currentEvent3.length > 2 ? currentEvent3[0].date : ''} </div>
                        </div>
                        {/* --- NAME --- */}
                        <div className = {width < 480 ? 'wrapper width-90 height-30 column font-400 weight-400' 
                        : 'wrapper width-90 height-20 column font-100 weight-400' } > {currentEvent3.length > 2 ? currentEvent3[0].name : ''} </div>
                        {/* --- PICTURE --- */}
                        <img className = 'wrapper width-100 height-60 ' src={currentEvent3.length > 2 ? `../img/${currentEvent3[0].picture}` : ''} alt="" onClick = {() => navigate(`${currentEvent3[0].pdf}`)}></img>
                    </div>
                </>
                :
                ''
                }
                {currentEvent3[1] !== undefined ?
                <>
                    {/* --- SECOND CARD --- */}
                    <div className = {width < 480 ? 'wrapper width-90 height-70 column justify-start align-center padding-top-1 margin-bottom-1 margin-right-1' 
                    : 'wrapper width-35 height-70 column justify-start align-center padding-top-1 margin-bottom-1 margin-right-1'} id = 'card'>
                        {/* --- HEAD WRAPPER --- */}
                        <div className = 'wrapper row width-90 height-10 justify-center margin-bottom-2' > 
                            <div className = {width < 480 ? 'wrapper width-60 height-100 column font-500 weight-500' 
                            : 'wrapper width-60 height-100 column font-300 weight-500' }> {currentEvent3.length > 2 ? currentEvent3[1].date : ''} </div>
                        </div>
                        {/* --- NAME --- */}
                        <div className = {width < 480 ? 'wrapper width-90 height-30 column font-400 weight-400' 
                        : 'wrapper width-90 height-20 column font-200 weight-400' } > {currentEvent3.length > 2 ? currentEvent3[1].name : ''} </div>
                        {/* --- PICTURE --- */}
                        <img className = 'wrapper width-100 height-60 ' src={currentEvent3.length > 2 ? `../img/${currentEvent3[1].picture}` : ''} alt="" onClick = {() => navigate(`${currentEvent3[1].pdf}`)}></img>
                    </div>
                </>
                :
                ''
                }
                {/* --- THIRD CARD --- */}
                {currentEvent3[2] !== undefined ?
                <>
                    <div className = {width < 480 ? 'wrapper width-90 height-70 column justify-start align-center padding-top-1 margin-bottom-1 margin-right-1' 
                    : 'wrapper width-25 height-50 column justify-start align-center padding-top-1 margin-bottom-1 margin-right-1'} id = 'card'>
                        {/* --- HEAD WRAPPER --- */}
                        <div className = 'wrapper row width-90 height-10 justify-center margin-bottom-2' > 
                            <div className = {width < 480 ? 'wrapper width-60 height-100 column font-500 weight-500' 
                            : 'wrapper width-60 height-100 column font-300 weight-500' }> {currentEvent3.length > 2 ? currentEvent3[2].date : ''} </div>
                        </div>
                        {/* --- NAME --- */}
                        <div className = {width < 480 ? 'wrapper width-90 height-30 column font-400 weight-400' 
                        : 'wrapper width-90 height-20 column font-100 weight-400' } > {currentEvent3.length > 2 ? currentEvent3[2].name : ''} </div>
                        {/* --- PICTURE --- */}
                        <img className = 'wrapper width-100 height-60 ' src={currentEvent3.length > 2 ? `../img/${currentEvent3[2].picture}` : ''} alt="" onClick = {() => navigate(`${currentEvent3[2].pdf}`)}></img>
                    </div>
     
                    <div className = {width < 480 ? 'arrowNone' : 'arrowright'}  id = 'arrowright' onClick = {() => handleCursor(1)}></div>
                </>
                :
                ''
                }
            </div>
        </div>
        : <div className = 'cont column justify-center align-center font-700' id = {width < 480 ? 'backMobile' : 'back' }><div className = 'wrapper width-80 height-30 text-center'><>Fordítsa a telefont álló helyzetbe!</></div></div>}
        
        </div>
    )

}

export default HomeView