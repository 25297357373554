import React, { useEffect, useState, useContext } from 'react'

import { useNavigate } from "react-router-dom";




const Program8: React.FunctionComponent = (props) => {

    const [ width, setWidth ] = React.useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {

    }, [])



    return(
        <div className = 'cont row justify-center align-top overflow-xnone overflow-ynone'  id = {width < 480 ? 'backMobile' : 'back' }>
            <div className = {width < 480 ? 'wrapper column width-90 height-90 align-center overflow-xnone overflow-y padding-top-25 ' : 'wrapper column width-90 height-90 align-center margin-top-5 overflow-xnone overflow-y white'} >
                <div className= {width < 480 ? "wrapper absolute row width-20 height-5 margin-left-50 margin-top-5 justify-center" : "wrapper absolute row width-10 height-5 margin-left-5 margin-top-1 justify-center" }><button className = 'button' onClick = {() => navigate('/')}>vissza</button></div>
                {/* --- OVERFLOW WRAPPER --- */}
                <div className = {width < 480 ? 'wrapper column width-100 height-100 justify-top align-center overflow-y margin-top-20' : 'wrapper column width-70 height-90 justify-top align-center margin-top-5 padding-top-3 margin-left-15 overflow-xnone overflow-y' } >
                    {/* --- HEAD --- */}
                    <div id = {width < 480 ? 'programHeadMobile' : 'programHead' }>
                        <div className = 'wrapper column width-100 align-center justify-top overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 color-800 weight-500 text-center' : 'wrapper column width-95 height-60 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' }>
                            Húsvéti kirándulás Pécsváradon!
                            </div>
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 color-800 weight-500 text-center' } >
                                Időpont: 2024. 04. 18 - 21.
                                <br></br>
                                Indulás 06.45-kor a Kert utcai benzinkúttól és 07.00-kor a Búzatéri parkolóból. Hazaérkezés kb 20.00-kor
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- DAY1 --- */}
                    <div id = {width < 480 ? 'programDayMobile' : 'programDay' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 weight-500 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                PROGRAMOK
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-400 ' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                                <b>2025. 04. 18, péntek </b>
                                <br></br>
                                Utazás autópályán Pécsváradra, szállás elfoglalása a pécsváradi várban az István Király szállodában két-három-négy-öt ágyas szobákban. Fürdő, wc a szobákban.A reggeli és vacsora félpanzió. Ezután múzeumlátogatás a várban séta Zengővár-konyban: ősgesztenyés Rockenbauer Pál síremléke, tojásmúzeum, szalmamúzeum, bormúzeum. Vacsora körülbelül 18:00 órakor.                                <br></br>
                                <br></br>
                                <br></br>
                                <b>2025. 04. 19, szombat </b>
                                <br></br>
                                Reggeli 7:00 órakor utána indulás busszal Pécsre idegenvezetőkkel városnézés: Misinatető, toronylátogatás alternatívan,  majd pécsi városnézés. Délután a Zsolnai Múzeumot látogatjuk meg amelyben páratlan szépségű és gazdag kiállítási anyaggal találkozunk ha időnk engedi megnézzük a Zsolnai  Mauzóleumot is. A Zsolnai múzeumba a belépő 3.000 Ft,  a  kedvezményes 1.500 Ft. Este vacsora 18:00 órakor
                                <br></br>
                                <br></br>
                                <b>2025. 04. 20,vasárnap  </b>
                                <br></br>
                                Reggeli 7:00 órakor utána indulás busszal Abaligetre barlanglátogatás és denevér-múzeum Barlangbelépő 3.000 Ft, a denevérmúzeummal együtt 3.800 Ft, diák, nyugdíjas 3.300 Ft., előzetes bejelentkezéssel. Busszal az Orfűi tavakhoz megyünk: orfűi malmok horgásztói séta,sárkányszakadék
                                <br></br>
                                Mecsekrákos:  Medvehagymaház,  Ökoturisztikai Központ, körbeutazzuk a tavat. Este a szálláson a  hölgy túratársakat meglocsoljuk, közben locsolóverseket mondunk. Aki nem kéri a locsolást, jelezze előre. Vacsora 18:00 órakor, locsolás vacsora után lesz.
                                <br></br>
                                <br></br>
                                <b>2025. 04. 21, hétfő </b>
                                <br></br>
                                Reggeli után bepakolás a  buszba, hazaindulás, útközben látogatást teszünk a Siklósi várban, nagyon szép és gazdag kiállítási anyaga van. Belépők felnőtteknek 3.500 Ft, diák-nyugdíjas 2.300 Ft, pedagógus 2300 Ft. 20 fős csoport 10%-ék kedvezmény, 70 év fölött 500 Ft. Ezután séta a borvárosban, Villányban kívánságra megnézzük a Bormúzeumot.
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- PAY --- */}
                    <div id = {width < 480 ? 'programPayMobile' : 'programPay' }>
                        <div className = 'wrapper column width-100 height-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 height-10 align-center overflow-xnone overflow-ynone font-300 weight-500 color-800 text-center' : 'wrapper column width-95 height-20 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                Részvételi díj és jelentkezés
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 height-90 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                Részvételi díj  86.000 ft amely tartalmazza a szállást,félpanzióval, a pécsi idegenvezetők díjait, a  sofőrt isztelettdíját, félpanzióját buszköltséget és utdíjakat. Lemondás esetén nem jár vissza az előleg ha nem küld valakit maga helyett. Ha az érkezésnapját megelőző három hat napon belül mondja le, akkor a szobaár 50 %-a fizetendő, ha egy-két napon belül mondja le, akkor a szobaár 100%-a fizetendő.
                                <br></br>
                                <br></br>
                                Hátralék befizetése:
                                <br></br>
                                <br></br>
                                Nagyné Ancikánál 06-20/428-0564 
                                <br></br>
                                <br></br>
                                vagy Gyüre Lászlónál  42/798-722 vagy 06-30/253-9348 
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- SKUNK --- */}
                    <div id = {width < 480 ? 'programSkunkMobile' : 'programSkunk' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-200 weight-400 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                            Ajánlott felszerelés: túra vagy sport öltözet, túracipő vagy túrabakancs,  az időjárásnak megfelelően, hátizsák vagy válltáska, esőkabát, vagy esernyő, kalap, túrabot,  1 napi hideg élelem, 1 liter víz, egyéni gyógyszer, zseblámpa, fényképezőgép, mobiltelefon, stb.  Túráink során vadon élő állatok támadására lehet számítani, ( medve, farkas, arany sakál, büdös borz, vaddisznó, róka, szarvas, őz stb.) Az általuk okozott károkért felelősséget nem tudunk vállalni! Kérem ezen tények tudomásul vételét
                                <br></br>
                                <br></br>
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-20 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                <br></br>
                                A programváltozás jogát fenntartjuk!
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Program8